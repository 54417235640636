@import 'assets/scss/variables';
@import 'assets/scss/theme';

.endorsement-container {
    color: #41474d;

    &__header {
        margin-bottom: 1.5rem;
    }

    &__wrapper {
        background-color: #f8f9ff;
        padding: 1rem 0 0 1rem;
        border-radius: calculateRem(8px);
        margin-bottom: 1.5rem;
        position: relative;

        &--sub-heading {
            font-size: 1.2rem;
            margin-bottom: 1.5rem;
        }
    }
}