@import 'assets/scss/variables';
@import 'assets/scss/theme';
@import 'assets/scss/mediaquery';

.menu-drawer {
    position: fixed;
    width: 100%;
    height: auto;
    min-height: 5rem;
    top: 4rem;
    background-color: #255EA7;
    padding: 2rem 3rem;
    box-sizing: border-box;
    transform: translateY(-100%);
    transition: transform 1s ease-out;
    margin-left: -1rem;
    z-index: 998;

    .app-header__options-container {
        @include flex(column, flex-start, flex-start);

        li.active {
            transform: translateX(-20px);
        }
    }

    @include for-breakpoint-greater-than-md {
        display: none;
    }
}

.open {
    transform: translateY(0%);
}

.close {
    display: none;
}