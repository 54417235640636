@import 'assets/scss/variables';
@import 'assets/scss/theme';
@import 'assets/scss/mediaquery';

.brokerages-container {
    &__header {
        @include flex(row, space-between, center);
        margin-bottom: 1.5rem;
    }

    &__wrapper {
        background-color: #f8f9ff;
        padding: 1rem;
        border-radius: calculateRem(8px);

        .search {
            &-container {
                @include flex(row, flex-start, center);
                gap: 1.5rem;

                .input-field {
                    @include for-breakpoint-greater-than-md {
                        width: 30%;
                    }
                }

                @include for-breakpoint-xs {
                    @include flex(column, flex-start, center);
                    gap: 1rem;
                    margin-bottom: 3rem;
                }
            }
        }

        .search-text__container {
            margin-top: 1.5rem;
            @include flex(row, flex-start, center);
            gap: 1.5rem;

            &--clear {
                padding: 0.3rem 1.3rem;

                .button__start-icon {
                    margin-right: 0.6rem;
                }
            }

            @include for-breakpoint-xs {
                @include flex(column, center, flex-start);
                gap: 1rem;
            }
        }
    }

    .brokerage-table {
        margin-top: 1.5rem;
        position: relative;

        .table-head {
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
            max-width: 80%;
        }

        .edit-action {
            min-width: 3rem;
        }
    }

    .action {
        &-container {
            @include flex(row, flex-end, center);
            gap: 0.5rem;
            height: 1.5rem;

            &__icon-delete,
            &__icon-edit {
                height: 1.5rem;
                width: 1.5rem;
                cursor: pointer;
            }
        }
    }

    // loader to show till search results are fetched
    .search-loader {
        position: relative;

        .search-content {
            position: absolute;
            top: 25%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        .scenery-container {
            margin-top: 2rem;
        }
    }
}