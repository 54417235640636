@import 'assets/scss/variables';
@import 'assets/scss/theme';
@import 'assets/scss/mediaquery';

.drawer-toggle {
    &--open {
        width: 1.5rem;
        height: 1.5rem;
        display: flex;
        flex-flow: column;
        justify-content: space-evenly;
        align-items: center;
        cursor: pointer;

        div {
            width: 90%;
            height: 2px;
            background-color: #FFF;
        }

        @include for-breakpoint-greater-than-md {
            display: none;
        }
    }

    &--close {
        width: 1.5rem;
        height: 1.5rem;
        position: relative;
        cursor: pointer;

        &:after {
            content: '';
            height: 100%;
            border-left: 2px solid #fff;
            position: absolute;
            transform: rotate(45deg);
            left: 50%;
        }

        &:before {
            content: '';
            height: 100%;
            border-left: 2px solid #fff;
            position: absolute;
            transform: rotate(-45deg);
            left: 50%;
        }
    }

}