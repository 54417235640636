@import 'assets/scss/variables';
@import 'assets/scss/theme';

.checkbox-container {
    @include flex(row, flex-start, center);
}

.checkbox-label {
    margin-left: calculateRem(8px);
}

.custom-checkbox {
    appearance: none;
    width: 1.2rem;
    height: 1.2rem;
    border: calculateRem(2px) solid #ccc;
    border-radius: calculateRem(3px);
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: border-color 0.2s;
}

.custom-checkbox:checked {
    border-color: #255ea7;
    background-color: #255ea7;
}

.custom-checkbox:checked:after {
    content: '\2713';
    color: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
