@import 'assets/scss/variables';
@import 'assets/scss/theme';

.button {
    padding: 0.5rem 1.2rem;
    font-size: calculateRem(14px);
    font-weight: 500;
    border: none;
    border-radius: 2rem;
    cursor: pointer;
    @include flex(row, flex-start, center);

    &__start-icon {
        @include flex();
        margin-right: 0.4rem;
    }

    &.disabled {
        cursor: not-allowed;
    }
}

.primary {
    background-color: #255ea7;
    color: #ffffff;
}

.secondary {
    background-color: white;
    color: #255ea7;
    border: calculateRem(1px) solid #72787e;

    &.disabled {
        color: rgba(25, 28, 29, 0.38);
        border: calculateRem(1px) solid rgba(25, 28, 29, 0.18);
    }
}

.default {
    background-color: white;
    color: #000;
}

.warn {
    background-color: #de3730;
    color: white;
}