// Mixins

@mixin bakcground-color($color) {
    background-color: $color;
}

@mixin text-color($color) {
    color: $color;
}

/* Background Image */
@mixin background-image($image) {
    background-image: url($image);
    background-repeat: no-repeat;
}

/* Place Holder */
@mixin placeholder {
    ::-webkit-input-placeholder {
        @content;
    }

    :-moz-placeholder {
        @content;
    }

    ::-moz-placeholder {
        @content;
    }

    :-ms-input-placeholder {
        @content;
    }
}

/* Scrollbar */
@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white, 50%)) {
    &::-webkit-scrollbar {
        width: $size;
        height: $size;
    }

    &::-webkit-scrollbar-thumb {
        background: $foreground-color;
    }

    &::-webkit-scrollbar-track {
        background: $background-color;
    }
}

/* Border Radius */
@mixin border-radius($radius) {
    border-radius: $radius;
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
}

/* Border */
@mixin border($width, $style, $color) {
    border-width: $width;
    border-style: $style;
    border-color: $color;
}

/* Box Shadow */
@mixin box-shadow($x-axis: 0, $y-axis: 1px, $blur: 2px, $color: $black) {
    box-shadow: $x-axis $y-axis $blur $color;
    -webkit-box-shadow: $x-axis $y-axis $blur $color;
}

/* Font Size */
@mixin font-size($size) {
    font-size: calculateRem($size);
}

@function calculateRem($size) {
    $remSize: $size / 16px;

    @return #{$remSize}rem;
}

/* Media Query */
@mixin ipad {
    @media only screen and (max-width: 1024px) and (min-width: 768px) {
        @content;
    }
}

@mixin ipad-portrait {
    @media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation: portrait) {
        @content;
    }
}

@mixin large-device {
    @media only screen and (min-width: 1300px) {
        @content;
    }
}

/* Transition */
@mixin transition($args...) {
    -webkit-transition: $args;
    -moz-transition: $args;
    -ms-transition: $args;
    -o-transition: $args;
    transition: $args;
}

/* Opacity */
@mixin opacity($opacity) {
    opacity: $opacity;
    $opacity-ie: $opacity * 100;
    filter: alpha(opacity=$opacity-ie); //IE8
}

/* Size */
@mixin size($w, $h) {
    width: $w;
    height: $h;
}

/* Square */
@mixin square($w) {
    @include size($w, $w);
}

/* Flex */
@mixin flex($direction: row, $alignH: center, $alignV: center) {
    display: flex;
    flex-direction: $direction;
    justify-content: $alignH;
    align-items: $alignV;
}

/* Grid */
@mixin grid($columns: auto auto, $alignV: center, $gap: auto) {
    display: grid;
    grid-template-columns: $columns;
    align-items: $alignV;
    grid-gap: $gap;
}

/* Absolute Center */
@mixin centre($axis: 'both') {
    position: absolute;

    @if $axis== 'y' {
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    @if $axis== 'x' {
        left: 50%;
        transform: translateX(-50%);
    }

    @if $axis== 'both' {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

// Extends

/* Remove Outline */
%no-outline {
    outline: none;
}

/* Remove Border */
%no-border {
    border: none;
}

/* Ellipse */
%ellipse {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

// Variables
$background-color: #fafafc;
$shadow-color: #c4c4cd59;
