@import 'assets/scss/variables';
@import 'assets/scss/theme';
@import 'assets/scss/mediaquery';

.radio-label {
    @include flex();
    gap: 0.5rem;
    gap: 0.3rem;
    color: #191C1D;
    font-size: calculateRem(16px);
    font-weight: 400;
    margin-right: 1rem;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
}

.radio-input {
    margin: 0;
    display: none;
}

.radio-input:checked+span {
    border: 2px solid #255EA7;
}

.radio-input:checked+span:after {
    opacity: 1;
}

.custom-radio {
    cursor: pointer;
    width: 1rem;
    height: 1rem;
    border: 2px solid #255EA7;
    border-radius: 50%;
    display: inline-block;
    position: relative;
}

/* for inner filled circle */
.custom-radio::after {
    content: '';
    width: 9px;
    height: 9px;
    background: #255EA7;
    position: absolute;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.2s;
}