@import 'assets/scss/variables';
@import 'assets/scss/theme';

.accordion-item {
    &__title {
        @include flex(row, space-between, center);
        padding: 0.7rem 1rem;
        cursor: pointer;
        background-color: #FFFFFF;
        border: 1px solid #E8E8EC;
    }

    &__content {
        border: 1px solid #E8E8EC;
        padding: 1rem;
    }

    &__arrow {
        height: 1.5rem;
        width: 1.5rem;
        transition: 0.2s ease;
        transform: rotate(-180deg);
    }

    &__open {
        .accordion-item {
            &__title {
                box-shadow: none;
                border-bottom: none;
            }

            &__arrow {
                transform: rotate(0deg);
                transition: 0.2s ease;
            }
        }

    }
}