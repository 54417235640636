@import 'assets/scss/variables';
@import 'assets/scss/theme';
@import 'assets/scss/mediaquery';

.app-footer {
    position: relative;
    background: #255ea7;
    color: #ffffff;
    margin-top: 2rem;

    &-container {
        padding: 2rem 3rem;
        @include flex(row, space-between, stretch);
        gap: 4rem;

        &__left {
            width: auto;

            &--nar-logo {
                width: calculateRem(150px);
            }

            &--realtor-logo {
                margin-right: 0.2rem;
            }

            &--sub-container {
                font-size: calculateRem(12px);
                margin-top: 1rem;

                p {
                    margin: 0.5rem 0;
                    line-height: 1rem;
                }
            }
        }

        &__vertical-line {
            border: calculateRem(1px) solid #6085b5;
            display: none;

            @include for-breakpoint-greater-than-md {
                display: block;
            }
        }

        &__right {
            @include flex(row, flex-start, stretch);
            gap: 4rem;

            .title {
                margin-bottom: 1rem;
            }

            .desc {
                font-size: calculateRem(12px);
                line-height: 1.2rem;
            }

            &--icons {
                @include flex(row, flex-start, center);
                gap: 0.8rem;

                a {
                    svg {
                        &:hover {
                            path {
                                fill: #2697e8 !important;
                            }
                        }
                    }
                }

            }

            &--adress-container,
            &--communication-container {
                @include flex(row, flex-start, stretch);
                gap: 4rem;

                @include for-breakpoint-xs {
                    gap: 2rem;

                    div {
                        width: 50% !important;
                    }
                }

                @include for-breakpoint-xs-to-sm {
                    width: 100% !important;
                }
            }

            &--communication-container {
                @include for-breakpoint-xs {
                    flex-direction: column;

                    div {
                        width: 100% !important;
                    }
                }
            }

            &--contact {
                @include flex(column, flex-start, stretch);
                gap: 1rem;

                &-row {
                    @include flex(row, flex-start, center);
                    gap: 0.8rem;
                    color: #fff;

                    .underline {
                        border-bottom: 1px solid #fff;
                    }

                    &.non-clickable {
                        cursor: default;

                        &:hover {
                            color: inherit !important;

                            svg {
                                path {
                                    fill: white !important;
                                }
                            }
                        }
                    }

                    &:hover {
                        color: #2988cc !important;

                        .underline {
                            border-bottom: 1px solid #2988cc !important;
                        }

                        svg {
                            path {
                                fill: #2697e8 !important;
                            }
                        }
                    }

                    @include for-breakpoint-xs-to-sm {
                        width: 100% !important;
                    }
                }
            }

            .font-small {
                margin: -1rem 0 0 2.5rem;
                font-size: calculateRem(10px);
                line-height: 1rem;
            }

            // to override default link styles
            a {
                text-decoration: none;
                color: inherit;
                white-space: nowrap;
                width: fit-content;

                &:visited {
                    color: inherit;
                }
            }


            @include for-breakpoint-xs {
                flex-direction: column;
                gap: 2rem;
            }

            @include for-breakpoint-xs-to-sm {
                gap: 2rem;
                flex-wrap: wrap;

                div {
                    width: calc(50% - 1rem);
                }
            }

            @include for-breakpoint-sm-to-md {
                flex-wrap: wrap;

                div {
                    width: 100%;
                }
            }
        }


        @include for-breakpoint-xs {
            flex-direction: column;
            gap: 2rem;
            padding: 1.5rem;
        }

        @include for-breakpoint-xs-to-sm {
            flex-wrap: wrap;
            flex-direction: column;
            gap: 2rem;
        }
    }
}