@import 'assets/scss/variables';
@import 'assets/scss/theme';
@import 'assets/scss/mediaquery';

.pagination {
    margin: 1rem 0;
    @include flex(row, flex-end, center);

    &-select {
        width: 7rem !important;

        .custom-select {
            &__header {
                border: none;
                justify-content: flex-start;
                height: fit-content;
            }

            &__selected-option,
            &__options {
                font-size: calculateRem(14px);
                font-weight: 300;
            }

            &__arrow {
                margin-top: calculateRem(-2px);
                margin-left: 0.2rem;
            }
        }
    }

    .default {
        padding: 0;
        margin: 0 0.2rem;

        &:disabled {
            img {
                filter: invert(0.8);
                cursor: not-allowed;
                border: none !important;
            }
        }
    }

    &__count {
        margin: 0 0.7rem;
        @include flex();
        gap: 0.3rem;

        @include for-breakpoint-xs {
            margin: 0;
        }
    }

    &--disabled {
        .pagination__count {
            opacity: 0.5;
        }
    }
}