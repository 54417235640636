@import 'assets/scss/variables';
@import 'assets/scss/theme';
@import 'assets/scss/mediaquery';

.date-picker {
    position: relative;
    width: 100%;

    &__header-controls {
        @include flex(row, space-around, center);
        gap: 1.5rem;

        &--year,
        &--month {
            @include flex(row, space-around, center);
            gap: 0.2rem;

            .prev-btn,
            .next-btn {
                cursor: pointer;
                padding: 0.2rem;
            }

            .year-select,
            .month-select {
                .custom-select {
                    &__header {
                        border: none;
                        gap: 0.3rem;
                        padding: 0 0.4rem;
                    }

                    &__selected-option {
                        font-size: calculateRem(14px);
                    }
                }
            }

            .month-select {
                .custom-select {
                    &__header {
                        &--has-value {
                            min-width: 2.3rem;
                        }
                    }
                }
            }
        }
    }

    .calendar {
        &__opened {
            border-color: #255ea7 !important;

            .input-field__label {
                color: #255ea7 !important;
            }
        }

        &__has-value {
            .input-field__label {
                color: #8b8b8b;
                top: 0;
                font-size: 12px;
                background: #f8f9ff;
                padding: 0 4px;
                left: 8px;
            }
        }

        &__icon {
            left: calc(100% - 2rem);
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            pointer-events: none;
        }
    }

    .input-field__label {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        padding: calculateRem(10px);
        left: 0;
    }

    @include for-breakpoint-xs {
        width: 100% !important;
    }

    @include for-breakpoint-xs-to-sm {
        width: calc(50% - 0.5rem) !important;
    }

    @include for-breakpoint-sm-to-md {
        width: calc(50% - 0.5rem) !important;
    }
}

//library related overrides
.react-datepicker {
    border: none;

    &-wrapper {
        width: 100%;

        .react-datepicker__input-container input {
            height: 3rem;
            width: 100%;
            border: calculateRem(1px) solid #ccc;
            border-radius: calculateRem(4px);
            font-size: 1rem;
            font-family: 'Roboto';
            padding: calculateRem(10px);
            cursor: pointer;
            background: transparent;
        }
    }

    &__day--selected {
        background-color: #255ea7;
        color: white;
        border-radius: 1rem;
    }

    &__header {
        background-color: #fff;
        padding: 1px 0;
        border-bottom: none;
    }
}

.react-datepicker-popper[data-placement^='bottom'] {
    padding-top: 0;
    z-index: 2;
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
    border-top: calculateRem(8px) solid transparent;
}