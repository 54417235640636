@import 'assets/scss/variables';
@import 'assets/scss/theme';
@import 'assets/scss/mediaquery';

.app-header {
    &__options {
        @include flex(row, flex-start, center);
        gap: 1.5rem;

        &-container {
            @include flex(row, flex-start, center);
            gap: 1.5rem;
            font-size: calculateRem(14px);
            display: none;

            li {
                text-align: center;
                line-height: 1rem;

                &.active {
                    padding: calculateRem(10px) calculateRem(17px);
                    border: calculateRem(1px) solid #ffffff;
                    border-radius: 3rem;
                    background: white;
                }
            }

            &--option {
                cursor: pointer;
                text-decoration: none;
                color: #ffffff;

                // class added by navLink for active link
                &.active {
                    color: #255ea7;
                    font-weight: 700;
                }
            }

            @include for-breakpoint-greater-than-md {
                display: flex;
            }
        }
    }
}