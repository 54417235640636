@import 'assets/scss/variables';
@import 'assets/scss/theme';
@import 'assets/scss/mediaquery';

.custom-select {
    position: relative;
    width: 100%;
    color: #191c1d;

    &--disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }

    &__header {
        position: relative;
        @include flex(row, space-between, center);
        padding: calculateRem(10px);
        height: 3rem;
        border: calculateRem(1px) solid #ccc;
        border-radius: calculateRem(4px);
        cursor: pointer;
        transition: border-color 0.2s;

        &--open {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border: calculateRem(1px) solid #255ea7;
        }

        &--has-value {
            .custom-select__placeholder-text {
                top: 0;
                font-size: calculateRem(12px);
                padding: 0 calculateRem(5px);
                background: #f8f9ff;
                transition: top 0.2s;
                position: absolute;
            }

            .custom-select__selected-option {
                font-size: 1rem;
                padding: 0 calculateRem(4px);
            }
        }

        &--disabled {
            pointer-events: none;
        }
    }

    &__placeholder-text {
        position: absolute;
        top: 50%;
        left: calculateRem(10px);
        transform: translateY(-50%);
        font-size: 1rem;
        color: #8b8b8b;
        transition: top 0.2s, font-size 0.2s;

        &--open {
            color: #255ea7;
        }
    }

    &__arrow {
        font-size: 1rem;
        transition: transform 0.2s;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #41474D;
        display: inline-block;
        cursor: pointer;

        &--open {
            transform: rotate(180deg);
        }
    }

    &__options {
        position: absolute;
        left: 0;
        width: 100%;
        background-color: #fff;
        box-shadow: 0 calculateRem(2px) calculateRem(4px) rgba(0, 0, 0, 0.2);
        z-index: 99;
        max-height: 12rem;
        overflow-y: auto;
        font-size: calculateRem(14px);
        @include scrollbars(0.4rem, #747480, #c4c4cd);

        li {
            padding: calculateRem(10px);
            cursor: pointer;

            &.custom-select__option--selected,
            &:hover {
                background-color: #f0f0f0;
            }
        }
    }
}