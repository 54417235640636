@import 'assets/scss/variables';
@import 'assets/scss/theme';

.view-progress-modal {
    font-weight: 400;

    .modal-body {
        padding: 0 1rem;
    }

    &__title {
        color: #41474D;
    }

    &__body {
        padding: 1rem 0;
        min-height: 28rem;
        position: relative;

        &--user-info {
            @include flex(column, flex-start, flex-start);
            gap: 0.3rem;

            .personal-info {
                font-size: 1.5rem;
                color: #41474D;
                margin-bottom: 0.7rem;

                &__name {
                    margin-right: 0.4rem;
                }
            }

            .date-info {
                font-size: calculateRem(14px);
                letter-spacing: 0.25px;
                margin-bottom: 0.5rem;

                &__label {
                    color: #72787E;
                    margin-right: 0.2rem;
                }

                &__date {
                    color: #41474D;
                }
            }
        }

        &--progress-wrapper {
            margin-top: 1rem;
            max-height: 17rem;
            overflow-y: scroll;
            font-size: 1rem;
            @include scrollbars(0.4rem, #747480, #c4c4cd);

            .accordion-title,
            .accordion-content-wrapper--row {
                @include flex(row, flex-start, center);
                gap: 0.5rem;
                font-weight: 500;

                &__image-wrapper {
                    @include flex();
                }
            }

            .accordion-content-wrapper {
                @include flex(column, flex-start, flex-start);
                gap: 1rem;

                &--row {
                    font-weight: 300;
                }
            }


            //overriding base component style
            .accordion-item {
                margin: 0 0.5rem 1rem 0;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        &--progress-legend {
            font-size: calculateRem(12px);
            font-weight: 400;
            @include flex(row, flex-start, center);
            gap: 1rem;
            padding: 1rem 0;
            position: absolute;
            bottom: 0;

            .legend-wrapper {
                @include flex();
                gap: 0.5rem;
            }
        }

        &--no-data {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}