@import 'assets/scss/variables';
@import 'assets/scss/theme';

.dropdown-options-container {
    position: absolute;
    top: 100%;
    background-color: #255ea7;
    width: 16rem;
    right: 0;
    font-size: calculateRem(14px);

    &__option-row {
        @include flex(row, flex-start, center);
        gap: 0.5rem;
        padding: 1rem;
        cursor: pointer;

        &:not(:last-child) {
            cursor: default;
            border-bottom: calculateRem(1px) solid #00468a;
        }

        &--profile-name {
            margin-left: 0.3rem;

            p {
                padding: 0.2rem 0;
            }
        }

        &--option-icon {
            height: 1.5rem;
            width: 2rem;

            &.reset {
                font-size: 2.3rem;
                line-height: 1.5rem;
                text-align: center;
            }
        }
    }

    .font-thin {
        font-weight: 300;

        &.role-label {
            font-size: calculateRem(12px);
        }
    }

    .font-700 {
        font-weight: 700;
    }
}