@import 'assets/scss/variables';
@import 'assets/scss/theme';
@import 'assets/scss/mediaquery';

.settings-container__wrapper {
    &--endorsement {
        margin-bottom: 2.5rem;

        .upload-container {
            &--wrapper {
                @include flex(column, flex-start, flex-start);
                gap: 1.5rem;
                width: 100%;

                .upload-container {
                    width: 55%;

                    .file-validation-info-wrapper {
                        @include flex(row, space-between, flex-start);

                        &--font-sm {
                            font-size: calculateRem(12px);
                            color: #72787e;
                            line-height: 1rem;
                        }

                        &--back-btn {
                            @include flex();
                            gap: 0.5rem;
                            color: #255EA7;
                            font-size: calculateRem(14px);
                            font-weight: 500;
                            cursor: pointer;
                        }

                        @include for-breakpoint-xs {
                            @include flex(column, flex-start, flex-start);
                            gap: 0.75rem;
                        }
                    }

                    @include for-breakpoint-xs {
                        width: calc(100% - 1rem);
                    }

                    @include for-breakpoint-xs-to-sm {
                        width: 80%;
                    }

                    @include for-breakpoint-sm-to-md {
                        width: 60%;
                    }
                }
            }
        }

        .download-container {
            width: 100%;
            @include flex(row, flex-start, center);
            gap: 0.5rem;

            &__file-name {
                @include flex(row, flex-start, center);
                width: 25%;
                gap: 0.5rem;
                font-size: calculateRem(14px);
                letter-spacing: 0.25px;
                color: #2E2E38;

                @include for-breakpoint-xs-to-sm {
                    width: 42%;
                }

                @include for-breakpoint-sm-to-md {
                    width: 40%;
                }
            }

            &__action-btns {
                @include flex();
                gap: 1rem;
                white-space: nowrap;
            }

            @include for-breakpoint-xs {
                @include flex(column, center, flex-start);
                gap: 0.7rem;
            }

            @include for-breakpoint-xs-to-sm {
                width: 100%;
                gap: 0.7rem;
            }

            @include for-breakpoint-sm-to-md {
                width: 80%
            }
        }
    }

    &--file-upload {
        @include flex();
        margin: 0.7rem 0;
    }

    &--list {
        list-style: disc;
        padding-left: 1rem;
    }

    &--font-md {
        font-size: calculateRem(14px);
    }
}