@import 'assets/scss/variables';
@import 'assets/scss/theme';

.modal {
    @include flex();
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;

    &-content {
        position: relative;
        background-color: white;
        border-radius: calculateRem(5px);
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
        overflow: hidden;
        padding-top: 0.7rem;
        padding-bottom: 0.5rem;
        border-radius: 1rem;
        margin: 0 1rem;
    }

    &-sm {
        width: calculateRem(300px);
    }

    &-md {
        width: calculateRem(500px);
    }

    &-lg {
        width: calculateRem(800px);
    }

    &-close {
        position: absolute;
        right: 1rem;
        top: 1.7rem;
        background: none;
        border: none;
        cursor: pointer;
    }
}