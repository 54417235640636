@import 'assets/scss/variables';
@import 'assets/scss/theme';

.edit-brokerage-modal {
    &__header {
        @include flex(column, flex-start, flex-start);
        gap: 0.8rem;
    }

    &__brokerage-info {
        font-size: calculateRem(14px);
        letter-spacing: 0.25px;
        margin-bottom: 0.5rem;
        @include flex();
        gap: 0.3rem;

        &__label {
            color: #72787E;
            margin-right: 0.2rem;
        }

        &__name {
            color: #41474D;
        }
    }

    &__body-content {
        padding: 1rem 2rem 2rem;
        @include flex(column);
        gap: 2rem;

        .input-wrapper {
            width: 100%;
            position: relative;

            &--error {
                color: #ff0000;
                font-size: calculateRem(12px);
                margin-top: 0.5rem;
            }

            .input-field {
                width: 100%;
            }
        }



        &--note {
            color: #191c1d;
            line-height: calculateRem(24px);
        }

        // overriding bg color for placeholders
        .input-field__has-value .input-field__label,
        .custom-select__header--has-value .custom-select__placeholder-text {
            background-color: #fff;
        }
    }
}