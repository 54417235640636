@import 'assets/scss/variables';
@import 'assets/scss/theme';

.custom-table-header-cell {
    background-color: $background-color;

    &__up-arrow {
        @include square(0rem);
        border-left: 0.25rem solid transparent;
        border-right: 0.25rem solid transparent;
        border-bottom: 0.25rem solid #2e2e38;
        display: inline-block;
        cursor: pointer;
        transform: translateY(-0.5rem);
    }

    &__down-arrow {
        @include square(0rem);
        border-left: 0.25rem solid transparent;
        border-right: 0.25rem solid transparent;
        border-top: 0.25rem solid #2e2e38;
        display: inline-block;
        cursor: pointer;
        transform: translate(-0.5rem);

        &--active {
            transform: translate(0);
        }
    }
}

.header-icon {
    vertical-align: text-bottom;
}
