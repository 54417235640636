// Define breakpoint variables
$breakpoint-xs: 576px;
$breakpoint-sm: 768px;
$breakpoint-md: 992px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1400px;

// Usage in media queries
@mixin for-breakpoint-xs {
    @media (max-width: #{$breakpoint-xs - 1}) {
        @content;
    }
}

@mixin for-breakpoint-xs-to-sm {
    @media (min-width: #{$breakpoint-xs}) and (max-width: #{$breakpoint-sm - 1}) {
        @content;
    }
}

@mixin for-breakpoint-sm-to-md {
    @media (min-width: #{$breakpoint-sm}) and (max-width: #{$breakpoint-md - 1}) {
        @content;
    }
}

@mixin for-breakpoint-greater-than-md {
    @media (min-width: #{$breakpoint-md}) {
        @content;
    }
}

@mixin for-breakpoint-md-to-lg {
    @media (min-width: #{$breakpoint-md}) and (max-width: #{$breakpoint-lg - 1}) {
        @content;
    }
}

@mixin for-breakpoint-lg-to-xl {
    @media (min-width: #{$breakpoint-lg}) and (max-width: #{$breakpoint-xl - 1}) {
        @content;
    }
}