@import 'assets/scss/variables';
@import 'assets/scss/theme';
@import 'assets/scss/mediaquery';

.input-field {
    position: relative;
    width: 100%;

    &__input {
        width: 100%;
        height: 3rem;
        padding: calculateRem(10px);

        border: calculateRem(1px) solid #ced4da;
        border-radius: calculateRem(4px);
        font-size: 1rem;
        background: transparent;

        &.has-start-icon {
            padding-left: 2rem;
        }

        &.has-end-icon {
            padding-right: 1.8rem;
        }

        &.read-only {
            border-color: #E1E3E3;
            cursor: not-allowed;
            color: #191C1D;
            opacity: 0.6;

            &:focus {
                border-color: #E1E3E3;
            }
        }

        &:focus {
            border-color: #255ea7;
        }
    }

    &__label {
        position: absolute;
        top: 50%;
        left: 0.5rem;
        padding: 0 calculateRem(5px);
        font-size: 1rem;
        color: #8b8b8b;
        transform: translateY(-50%);
        pointer-events: none;
        transition: all 0.2s ease;

        &.has-start-icon {
            padding-left: 2rem;
            left: 0;
        }
    }

    &__focused,
    &__has-value {
        .input-field__label {
            top: 0;
            font-size: calculateRem(12px);
            left: calculateRem(10px);
            color: #255ea7;
            background-color: #f8f9ff;
            padding-left: calculateRem(4px);

            &.read-only {
                color: #8b8b8b;
                background-color: #fff;
            }
        }
    }

    &__has-value {
        .input-field__label {
            color: #8b8b8b;
        }
    }

    @include for-breakpoint-xs {
        width: 100% !important;
    }

    @include for-breakpoint-xs-to-sm {
        width: calc(50% - 0.5rem);
    }

    @include for-breakpoint-sm-to-md {
        width: calc(50% - 0.5rem);
    }
}

.input-field__icon-container {

    &-right,
    &-left {
        position: absolute;
        @include flex(row, flex-start, center);
        height: 100%;
        left: calculateRem(8px);

        .input-field__icon {
            width: 1.2rem;
            cursor: pointer;
        }
    }

    &-right {
        top: 0;
        left: calc(100% - 1.5rem);
    }
}