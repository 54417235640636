@import 'assets/scss/variables';
@import 'assets/scss/theme';

.file-upload {
    height: 5rem;
    background-image: linear-gradient(to right, #c4c4cd 50%, transparent 50%),
        linear-gradient(to right, #c4c4cd 50%, transparent 50%),
        linear-gradient(to bottom, #c4c4cd 50%, transparent 50%),
        linear-gradient(to bottom, #c4c4cd 50%, transparent 50%);
    background-position: left top, left bottom, left top, right top;
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 20px 1px, 20px 1px, 1px 20px, 1px 20px;
    background-color: #f6f6fa;

    &:hover {
        background-image: linear-gradient(to right, #2e2e38 50%, transparent 50%),
            linear-gradient(to right, #2e2e38 50%, transparent 50%),
            linear-gradient(to bottom, #2e2e38 50%, transparent 50%),
            linear-gradient(to bottom, #2e2e38 50%, transparent 50%);
    }

    &__label-wrapper {
        @include flex(column, center, center);
        font-size: calculateRem(14px);
        font-weight: 300;
        gap: 0.3rem;

        &--label {
            text-align: center;
        }
    }

    &__anchor-btn {
        color: #155cb4;
        font-weight: 700;
        cursor: pointer;
    }
}
