@import 'assets/scss/variables';
@import 'assets/scss/theme';
@import 'assets/scss/mediaquery';

.edit-user-modal {
    &__body-content {
        padding: 1rem 3rem;
        @include flex(column);
        gap: 1.5rem;

        .input-field {
            width: 100%;
        }

        &--controls {
            @include flex(column, flex-start, unset);
            gap: 2rem;
            font-size: calculateRem(14px);
            color: #255ea7;
            width: 100%;

            &--top {
                @include flex(row, flex-start, center);
            }

            &--bottom {
                @include flex(row, flex-start, baseline);
            }

            .control {
                &__wrapper {
                    cursor: pointer;
                    @include flex(row, flex-start, center);
                    gap: 0.5rem;
                    min-width: 50%;
                }

                &:last-child {
                    @include flex(column, flex-start, flex-start);
                    position: relative;

                    .sync-date {
                        position: relative;
                        left: 1.6rem;
                        color: #41474d;
                        font-size: calculateRem(12px);
                    }
                }
            }
        }

        &--broker-controls {
            @include flex(row, flex-start, center);

            .control__wrapper {
                @include flex(row, center, center);
                gap: 0.5rem;
                min-width: auto;
                cursor: pointer;
            }
        }

        @include for-breakpoint-xs {
            padding: 1rem;
        }

        // overriding bg color for placeholders
        .input-field__has-value .input-field__label,
        .custom-select__header--has-value .custom-select__placeholder-text {
            background-color: #fff;
        }
    }
}