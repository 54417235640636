@import 'assets/scss/variables';
@import 'assets/scss/theme';
@import 'assets/scss/mediaquery';

.delete-modal {
    &__body-content {
        padding: 1rem 3rem;

        @include for-breakpoint-xs {
            padding: 1rem;
        }
    }
}