@import 'assets/scss/variables';
@import 'assets/scss/theme';

.colored-line {
    z-index: 999;
    width: 100%;
    background: linear-gradient(
        to right,
        #61d19e 0%,
        #61d19e 25%,
        #ffd94d 25%,
        #ffd94d 50%,
        #ff8785 50%,
        #ff8785 75%,
        #004282 75%,
        #004282 100%
    );
}
