@import 'assets/scss/variables';
@import 'assets/scss/theme';

.custom-table {
    &__container {
        font-size: calculateRem(12px);
        line-height: calculateRem(20px);

        .header-icon {
            margin-left: 0.5rem;
        }
    }

    &__scrollable {
        overflow-x: auto;
        @include scrollbars(0.4rem, #747480, #c4c4cd);
        margin-top: 0;
    }

    &__header {
        @include flex(row, flex-start, stretch);
        position: sticky;
        top: 0;
        z-index: 1;

        .custom-table__cell {
            text-transform: uppercase;
            font-size: 0.625rem;
            font-weight: 700;
            letter-spacing: 0.0625rem;
            overflow: unset;
            background-color: #f8f9ff;
            line-height: 1rem;
            @include flex(flex, flex-start, baseline);

            .table-head {
                overflow: visible !important;
                white-space: normal !important;
                text-overflow: clip !important;
                height: auto !important;
            }
        }
    }

    &__row,
    &__sub-row {
        @include flex(row, flex-start, center);

        .custom-table__cell {
            min-height: 2.575rem;
        }
    }

    &__sub-row {
        .custom-table__cell:first-child {
            p {
                padding-left: 1rem;
            }
        }
    }

    &__cell {
        flex: 1;
        min-width: 8rem;
        padding: calculateRem(8px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #2e2e38;
        font-size: 0.875rem;
        font-weight: 400;
        border-bottom: 1px solid #ccc;
        line-height: 1.5rem;

        p {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}