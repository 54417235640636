@import 'assets/scss/variables';
@import 'assets/scss/theme';
@import 'assets/scss/mediaquery';

.users-container {
    &__header {
        @include flex(row, space-between, flex-start);
        margin-bottom: 1.5rem;

        &--action-btns {
            @include flex(row, flex-start, flex-start);
            gap: 1rem;

            .download-csv-status {
                @include flex();
                align-self: center;
                gap: 0.5rem;

                .label {
                    font-size: calculateRem(14px);
                    color: #2E2E38;
                }

                @include for-breakpoint-xs {
                    align-self: flex-start;
                }
            }

            .generate-csv-wrapper {
                @include flex(column, center, flex-start);
                gap: 0.5rem;
                position: relative;

                .generated-info {
                    font-size: calculateRem(12px);
                    color: #41474d;
                    text-wrap: nowrap;
                    white-space: nowrap;
                    position: absolute;
                    bottom: -1rem;

                    &__date {
                        letter-spacing: 0.5px;
                    }

                    @include for-breakpoint-xs {
                        position: static;
                    }
                }

                @include for-breakpoint-xs-to-sm {
                    margin-bottom: 1rem;
                }
            }

            @include for-breakpoint-xs {
                @include flex(column, flex-start, flex-start);
                gap: 0.5rem;
            }

            @include for-breakpoint-xs-to-sm {
                flex-wrap: wrap;
                gap: 0.5rem;

                .download-csv-status,
                button.secondary {
                    width: max-content;
                }

                .generate-csv-wrapper {
                    width: max-content;

                    button.secondary {
                        width: 100%;
                    }
                }
            }
        }

        @include for-breakpoint-xs {
            @include flex(column, flex-start, flex-start);
            gap: 1rem;
        }

        @include for-breakpoint-xs-to-sm {
            gap: 3rem;
        }
    }

    &__body {
        position: relative;
        padding: 1rem;
        background-color: #f8f9ff;
        border-radius: calculateRem(8px);

        &--no-data {
            @include flex();
            margin-top: 5rem;

            @include for-breakpoint-xs {
                margin: 4rem 0;
            }

            @include for-breakpoint-xs-to-sm {
                margin-bottom: 1rem;
            }
        }

        .search-form-container {
            @include flex(row, flex-start, center);
            gap: 1rem;


            @include for-breakpoint-xs {
                @include flex(column, center, center);
                margin-bottom: 3rem;
            }

            @include for-breakpoint-xs-to-sm {
                flex-wrap: wrap;
                margin-bottom: 2rem;

                .search-btn {
                    margin: auto;
                }
            }

            @include for-breakpoint-sm-to-md {
                flex-wrap: wrap;
                margin-bottom: 2rem;

                .search-btn {
                    margin: auto;
                }
            }
        }

        .search-text__container {
            margin-top: 1.5rem;
            @include flex(row, flex-start, center);
            gap: 1.5rem;

            &--clear {
                padding: 0.3rem 1.3rem;

                .button__start-icon {
                    margin-right: 0.6rem;
                }
            }

            @include for-breakpoint-xs {
                @include flex(column, center, flex-start);
                gap: 1rem;
            }
        }

        .users-table {
            margin-top: 1.5rem;
            position: relative;

            .table-head {
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-block;
                max-width: 80%;
            }

            .edit-action {
                min-width: 3rem;
            }
        }

        .action {
            &-container {
                @include flex(row, flex-end, center);
                height: 1.5rem;

                &__icon-delete,
                &__icon-edit {
                    height: 1.5rem;
                    width: 1.5rem;
                    cursor: pointer;
                }
            }
        }

        // loader to show till search results are fetched
        .search-loader {
            position: relative;
            white-space: nowrap;

            .search-content {
                position: absolute;
                top: 25%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            .scenery-container {
                margin-top: 2rem;
            }
        }
    }

    @include for-breakpoint-xs {
        margin-bottom: 1rem;
    }

    @include for-breakpoint-xs-to-sm {
        margin-bottom: 1rem;
    }
}