@import 'assets/scss/variables';
@import 'assets/scss/theme';

.loader {
    border: calculateRem(4px) solid transparent;
    border-top: calculateRem(4px) solid #255ea7;
    border-radius: 50%;
    width: calculateRem(40px);
    height: calculateRem(40px);
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
