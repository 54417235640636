// --------------------------------------------------
// Typography Variables
// --------------------------------------------------
@import 'variables';
@import 'base';
@import 'theme';

body {
    @include scrollbars(0.4rem, #747480, #c4c4cd);

    img {
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
        user-drag: none;
    }
}
