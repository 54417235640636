@import 'assets/scss/variables';
@import 'assets/scss/theme';
@import 'assets/scss/mediaquery';

.app-header {
    margin: 0;
    padding: 0 1rem;
    position: fixed;
    background: #255ea7;
    width: 100%;
    height: 4rem;
    z-index: 100;
    @include flex(row, space-between, center);
    color: #ffffff;

    &-left {
        @include flex(row, flex-start, center);
        height: 100%;
        gap: 1.5rem;

        @include for-breakpoint-xs {
            gap: 1rem;
        }
    }

    &__logo-container {
        @include flex(row, flex-start, center);
        gap: 1rem;
        cursor: pointer;

        &--logo {
            display: inline-block;
            padding-right: 1rem;

            &.vertical-line {
                border-right: calculateRem(1px) solid #ffffff;

                &.line {
                    @include for-breakpoint-xs {
                        border-right: none;
                        padding-right: 0;
                    }
                }
            }

            @include for-breakpoint-xs {
                width: 50%;
            }
        }
    }

    &__title-container {
        text-transform: uppercase;
        @include flex(column, flex-start, stretch);
        gap: 0.3rem;
        font-size: calculateRem(14px);

        &--text {
            font-weight: 400;
            letter-spacing: calculateRem(0.5px);
        }

        @include for-breakpoint-xs {
            display: none;
        }
    }

    &__user-profile-container {
        @include flex(row, flex-start, center);
        gap: 0.3rem;
        cursor: pointer;

        &--initials {
            height: 2rem;
            width: 2rem;
            @include flex();
            border: 1px solid white;
            color: white;
            background-color: #006BB6;
            font-size: 1rem;
            border-radius: 50%;
            font-weight: 300;
        }
    }

    @include for-breakpoint-xs {
        gap: 1.5rem;
    }

    @include for-breakpoint-greater-than-md {
        padding: 0 1rem 0 2rem;
    }
}

.header-colored-line {
    position: fixed;
    margin-top: 4rem;
}