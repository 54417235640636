@import 'assets/scss/variables';
@import 'assets/scss/theme';
@import 'assets/scss/mediaquery';

.scenery-container {
    pointer-events: none;
    @include flex(row, flex-end, center);
    max-width: 100%;

    .scenery {
        max-width: 100%;
        height: auto;
    }

    @include for-breakpoint-xs {
        margin-top: 7rem;
    }

    @include for-breakpoint-xs-to-sm {
        margin-top: 4rem;
    }
}