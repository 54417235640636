@import 'assets/scss/variables';
@import 'assets/scss/theme';

.site-settings-scenery-container {
    pointer-events: none;
    @include flex(row, flex-end, center);
    max-width: 100%;
    margin-top: -10%;

    .site-scenery {
        max-width: 100%;
        height: auto;
    }
}