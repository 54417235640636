@import 'assets/scss/variables';
@import 'assets/scss/theme';
@import 'assets/scss/mediaquery';

.external-user-modal {
    &__body-content {
        padding: 1rem 3rem 2rem;
        @include flex(column, flex-start, flex-start);
        gap: 1.5rem;

        .input-wrapper,
        .select-wrapper {
            width: 100%;
            position: relative;

            &--error {
                color: #ff0000;
                font-size: calculateRem(12px);
                margin-top: 0.5rem;
            }

            .input-field {
                width: 100%;
            }
        }

        .radio-wrapper {
            @include flex(column, flex-start, flex-start);
            gap: 1rem;

            .label {
                font-size: calculateRem(12px);
                color: #8b8b8b;
            }

            &__btn-group {
                @include flex();
            }
        }

        @include for-breakpoint-xs {
            padding: 1rem;
        }

        // overriding bg color for placeholders
        .input-field__has-value .input-field__label,
        .custom-select__header--has-value .custom-select__placeholder-text {
            background-color: #fff;
        }
    }
}