@import 'assets/scss/variables';
@import 'assets/scss/theme';

.download-loader {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background:
        radial-gradient(farthest-side, #3AE180 94%, #0000) top/5px 5px no-repeat,
        conic-gradient(#0000 30%, #3AE180);
    -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 5px), #000 0);
    mask: radial-gradient(farthest-side, #0000 calc(100% - 5px), #000 0);
    animation: spin 1s infinite linear;
}

@keyframes spin {
    100% {
        transform: rotate(1turn)
    }
}