@import 'assets/scss/variables';
@import 'assets/scss/theme';

.toast {
    position: fixed;
    z-index: 999;
    font-size: 14px;
    transition: opacity 0.3s ease-in-out;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 0px 16px 0px #00000034;
    min-width: 10rem;
    background: #FFF;

    &-content {
        @include flex(row, space-between, center);

        &__left {
            @include flex();

            &--icon {
                padding: 0.5rem;
            }

            p {
                font-family: 'EYInterstate';
                font-weight: 300;
                padding: 0 1rem;
            }
        }

        &__right {
            @include flex();
            margin-right: 0.5rem;
            cursor: pointer;
        }
    }
}

.top-position {
    top: 7rem;
}

.bottom-position {
    bottom: 7rem;
}

.info-toast {
    .toast-content__left--icon {
        background-color: #004282;
    }
}

.warning-toast {
    .toast-content__left--icon {
        background-color: #ffd94d;
    }
}

.error-toast {
    .toast-content__left--icon {
        background-color: #B9251C;
    }
}

.success-toast {
    .toast-content__left--icon {
        background-color: #61d19e;
    }
}