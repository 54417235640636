@import 'assets/scss/variables';
@import 'assets/scss/theme';
@import 'assets/scss/mediaquery';

.external-users-container {
    &__header {
        @include flex(row, space-between, center);
        margin-bottom: 1.5rem;

        &--back-btn {
            @include flex(row, flex-start, center);
            gap: 0.5rem;
            margin-bottom: 1rem;
            font-size: calculateRem(14px);
            cursor: pointer;
            width: fit-content;
        }

        &--action-btn {
            @include flex(row, flex-start, center);
            gap: 1rem;

            .download-csv-status {
                @include flex();
                gap: 0.5rem;

                .label {
                    font-size: calculateRem(14px);
                    color: #2E2E38;
                }
            }
        }
    }

    &__body {
        position: relative;
        padding: 1rem;
        background-color: #f8f9ff;
        border-radius: calculateRem(8px);

        &--no-data {
            @include flex();
            margin-top: 5rem;

            @include for-breakpoint-xs {
                margin: 4rem 0;
            }

            @include for-breakpoint-xs-to-sm {
                margin-bottom: 1rem;
            }
        }

        .search-form-container {
            @include flex(row, flex-end, center);
            gap: 1rem;

            @include for-breakpoint-xs {
                @include flex(column, center, center);
                margin-bottom: 3rem;
            }

            @include for-breakpoint-greater-than-md {
                .input-field {
                    width: 30%;
                }
            }
        }

        .search-text__container {
            margin-top: 1.5rem;
            @include flex(row, flex-start, center);
            gap: 1.5rem;

            &--clear {
                padding: 0.3rem 1.3rem;

                .button__start-icon {
                    margin-right: 0.6rem;
                }
            }

            @include for-breakpoint-xs {
                @include flex(column, center, flex-start);
                gap: 1rem;
            }
        }

        .external-users-table {
            margin-top: 1.5rem;
            position: relative;

            .table-head {
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-block;
                max-width: 80%;
            }
        }

        .action {
            &-container {
                @include flex(row, flex-end, center);
                gap: 0.7rem;
                height: 1.5rem;

                &__icon-delete,
                &__icon-edit {
                    height: 1.5rem;
                    width: 1.5rem;
                    cursor: pointer;

                    &.disabled {
                        cursor: not-allowed;
                        filter: opacity(0.5);
                    }
                }
            }
        }

        // loader to show till search results are fetched
        .search-loader {
            position: relative;
            white-space: nowrap;

            .search-content {
                position: absolute;
                top: 25%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            .scenery-container {
                margin-top: 2rem;
            }
        }
    }

    @include for-breakpoint-xs {
        margin-bottom: 1rem;
    }

    @include for-breakpoint-xs-to-sm {
        margin-bottom: 1rem;
    }
}