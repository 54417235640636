@import 'assets/scss/variables';
@import 'assets/scss/theme';

.auth-layout {
    overflow: hidden;

    &__body {
        min-height: calc(100vh - 7rem);
        top: 4rem;
        padding: 2rem;
        position: relative;
    }
}
