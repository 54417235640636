@import 'assets/scss/variables';
@import 'assets/scss/theme';
@import 'assets/scss/mediaquery';

.unauthorized-layout {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    height: calc(100vh - 9rem);
    color: black;

    &-info-msg {
        font-size: 1.2rem;
        margin-bottom: 1rem;
        line-height: 1.5rem;
        text-align: center;

        &--link {
            color: #007bff;
            text-decoration: underline;
            cursor: pointer;
        }

        @include for-breakpoint-xs {
            font-size: 1rem;
        }

        @include for-breakpoint-xs-to-sm {
            font-size: 1rem;
        }

        @include for-breakpoint-sm-to-md {
            font-size: 1rem;
        }
    }
}