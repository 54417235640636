@import 'assets/scss/variables';
@import 'assets/scss/theme';
@import 'assets/scss/mediaquery';

.file-uploader-item {
    width: 55%;

    .file-details {
        @include flex(row, space-between, center);
        font-weight: 300;
        color: #2E2E38;
        font-size: calculateRem(14px);
        padding: 0.2rem 0.7rem;

        &__name {
            @include flex(row, flex-start, center);
            gap: 0.3rem;
        }

        &__actions {
            @include flex(row, flex-start, center);
            gap: 1rem;
            font-size: calculateRem(12px);

            .cancel-btn {
                cursor: pointer;

                @include for-breakpoint-xs {
                    position: absolute;
                    right: 1rem;
                }
            }
        }

        @include for-breakpoint-xs {
            @include flex(column, center, flex-start);
            gap: 0.5rem;
            padding-bottom: 0.5rem;
        }
    }

    .upload-progress__row {
        &--outerline {
            width: 100%;
            height: 4px;
            background-color: #E7E7EA;
        }

        &--innerline {
            height: 4px;
            background-color: #006BB7;
        }
    }

    @include for-breakpoint-xs {
        width: calc(100% - 1rem);
    }

    @include for-breakpoint-xs-to-sm {
        width: 80%;
    }

    @include for-breakpoint-sm-to-md {
        width: 60%;
    }
}