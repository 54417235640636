@import 'assets/scss/variables';
@import 'assets/scss/theme';

.custom-LegendDot {
    &__wrapper {
        @include flex(row, flex-start, center);
    }

    width: calculateRem(10px);
    height: calculateRem(10px);
    border-radius: 50%;
    margin-right: 0.5rem;
}
