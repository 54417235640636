@import 'assets/scss/variables';
@import 'assets/scss/theme';

.validation-modal {
    &__body-content {
        padding: 1rem 3rem 3rem;
        @include flex(column, center, flex-start);
        gap: 1.5rem;
        letter-spacing: calculateRem(0.5px);

        &--para {
            line-height: calculateRem(24px);
        }
    }
}