@import 'assets/scss/variables';
@import 'assets/scss/theme';
@import 'assets/scss/mediaquery';

.reports-container {
    &__header {
        @include flex(row, space-between, flex-start);
        margin-bottom: 1.5rem;

        &--action-btns {
            @include flex(row, flex-start, flex-start);
            gap: 1rem;

            .download-csv-status {
                @include flex();
                gap: 0.5rem;
                align-self: center;

                .label {
                    font-size: calculateRem(14px);
                    color: #2E2E38;
                }

                @include for-breakpoint-xs {
                    align-self: flex-start;
                }
            }

            .generate-csv-wrapper {
                @include flex(column, center, flex-start);
                gap: 0.5rem;
                position: relative;

                .generated-info {
                    font-size: calculateRem(12px);
                    color: #41474d;
                    text-wrap: nowrap;
                    white-space: nowrap;
                    position: absolute;
                    bottom: -1rem;

                    &__date {
                        letter-spacing: 0.5px;
                    }

                    @include for-breakpoint-xs {
                        position: static;
                    }
                }
            }

            @include for-breakpoint-xs {
                @include flex(column, flex-start, flex-start);
                gap: 0.5rem;
            }

            @include for-breakpoint-xs-to-sm {
                flex-wrap: wrap;
                gap: 0.5rem;

                .download-csv-status,
                button.secondary {
                    width: max-content;
                }

                .generate-csv-wrapper {
                    width: max-content;

                    button.secondary {
                        width: 100%;
                    }
                }
            }
        }

        @include for-breakpoint-xs {
            @include flex(column, flex-start, flex-start);
            gap: 1rem;
        }
    }

    &__body {
        position: relative;
        padding: 1rem;
        background-color: #f8f9ff;
        border-radius: calculateRem(8px);
        margin-bottom: 1rem;

        &--no-data {
            @include flex();
            margin-top: 5rem;
        }

        &--select-container {
            @include flex(row, flex-start, center);
            gap: 1rem;
            width: 100%;
            .report-select-field{
                width: 30% !important;
            }

            @include for-breakpoint-xs {
                @include flex(column, center, flex-start);
                gap: 1rem;
                width: 100%;
            }

            @include for-breakpoint-xs-to-sm {
                width: calc(50% - 0.5rem);
            }
        }

        &--formfields-container {
            @include flex(row, flex-start, center);
            gap: 1rem;
            margin-top: 1.5rem;

            .input-field,
            .report-primary-association-field {
                width: 30%;

                @include for-breakpoint-xs-to-sm {
                    width: 50%;
                }

                @include for-breakpoint-xs-to-sm {
                    width: calc(50% - 0.5rem);
                }
            }

            .report-search-field {
                width: 100%;
            }

            @include for-breakpoint-xs {
                @include flex(column, center, center);
                gap: 1rem;
                width: 100%;
            }

            @include for-breakpoint-xs-to-sm {
                flex-wrap: wrap;

                .search-btn {
                    margin: auto;
                }
            }
        }

        &--search-info {
            @include flex(row, flex-start, center);
            gap: 1.5rem;
            margin-top: 1.5rem;

            .clear-btn {
                padding: 0.3rem 1.3rem;
            }

            @include for-breakpoint-xs {
                @include flex(column, center, flex-start);
                gap: 1rem;
                margin-bottom: 3rem;
            }
        }

        .reports-table {
            margin-top: 1.5rem;
            position: relative;

            .table-head {
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-block;
                max-width: 80%;
            }
        }

        // loader to show till search results are fetched
        .search-loader {
            position: relative;

            .search-content {
                position: absolute;
                top: 25%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}